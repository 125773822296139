import React from "react"
import { useLocalization } from "gatsby-theme-i18n"
import styled from "styled-components"
import { graphql, navigate } from "gatsby"
import { createCollection } from "@actions/collection"
import { StickyContainer, Sticky } from "react-sticky"
import { useTranslation } from "react-i18next"
import { Layout } from "@bw/layouts"
import { HighlightTeaser, BecomeAnArtist } from "@bw/partials"
import { toast } from "react-toastify"
import { Section, Button, Avatar, Banner, Checkbox, Grid } from "@bw/bits"
import { breakpoints } from "../../theme"
import { FormField, Input, FileInput, TextArea } from "@bw/forms"
import { Formik, Form, Field } from "formik"
import { useUser } from "@contexts/user"
import * as yup from "yup"

const placeholder = "█"
const transparentPixel =
  "data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="

const PlaceHolder = styled.span`
  letter-spacing: -1px;
  line-break: anywhere;
  user-select: none;
`

const GridContainer = styled.div`
  display: grid;
  width: 100%;

  @media (min-width: ${breakpoints.medium}px) {
    grid-template-columns: 320px 1fr;
    column-gap: 230px;
  }
`

const Tags = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 5px;
`

const FormTitle = styled.h3`
  margin-bottom: 0.5em;
`

const FormHelp = styled.div`
  font-size: 14px;
  margin-bottom: 2em;
  opacity: 0.7;
`

const validationSchemaStep = [
  yup.object().shape({
    name: yup.string().required(),
    family: yup.string().required(),
    symbol: yup.string().min(2).max(10).required(),
    description: yup.string().required(),
  }),
  yup.object().shape({
    icon_path: yup
      .mixed()
      .required()
      .test(
        "image-is-small-enough",
        "Image must be under 2MB",
        value => value?.size / 1024 / 1024 < 2
      ),
    cover_path: yup
      .mixed()
      .required()
      .test(
        "image-is-small-enough",
        "Image must be under 2MB",
        value => value?.size / 1024 / 1024 < 2
      ),
    vertical_teaser_path: yup
      .mixed()
      .required()
      .test(
        "image-is-small-enough",
        "Image must be under 2MB",
        value => value?.size / 1024 / 1024 < 2
      ),
    square_teaser_path: yup
      .mixed()
      .required()
      .test(
        "image-is-small-enough",
        "Image must be under 2MB",
        value => value?.size / 1024 / 1024 < 2
      ),
  }),
  yup.object().shape({
    tags: yup.array(yup.string()),
    is_nsfw: yup.array(yup.string()).nullable(),
  }),
  yup.object().shape({
    supply_number: yup.number().nullable(),
    initial_price: yup.number().nullable(),
    minted_at: yup.mixed().nullable(),
  }),
  yup.object().shape({
    website: yup.string().url(),
    discord: yup.string().url(),
    telegram: yup.string().url(),
    instagram: yup.string().url(),
    twitter: yup.string().url(),
  }),
]

const fullValidationSchema = validationSchemaStep.reduce(
  (mergedSchemas, schema) => mergedSchemas.concat(schema),
  yup.object()
)

const Create = ({ data, pageContext }) => {
  const tags = data.allTag.nodes || []
  const { localizedPath, locale, defaultLang } = useLocalization()
  const [user] = useUser()
  const [step, setStep] = React.useState(0)
  const { t } = useTranslation()
  const stickyRef = React.useRef()
  const [previews, setPreviews] = React.useState({
    cover_path: transparentPixel,
    vertical_teaser_path: transparentPixel,
    square_teaser_path: transparentPixel,
    icon_path: transparentPixel,
  })

  if (typeof window === "undefined") {
    // FileReader cant' be server side rendered
    return null
  }

  if (!user.loggedIn) {
    navigate(
      localizedPath({
        defaultLang,
        locale,
        path: `/user/login/`,
      })
    )
    return null
  }

  if (!user.data.is_artist) {
    return (
      <Layout {...{ pageContext }} $background="#f3f4fa">
        <BecomeAnArtist />
      </Layout>
    )
  }

  const reader = new FileReader()
  const isLastStep = step === validationSchemaStep.length - 1

  return (
    <Layout {...{ pageContext }} $background="#f3f4fa">
      <Section
        title={t("collection.createTitle")}
        subtitle={t("collection.createSubtitle")}
      />
      <Section>
        <Formik
          validationSchema={validationSchemaStep[step]}
          initialValues={{
            name: "",
            family: "",
            symbol: "",
            description: "",
            icon_path: null,
            cover_path: null,
            vertical_teaser_path: null,
            square_teaser_path: null,
            tags: [],
            is_nsfw: 0,
            supply_number: null,
            initial_price: null,
            minted_at: null,
            website: "",
            discord: "",
            telegram: "",
            instagram: "",
            twitter: "",
          }}
          onSubmit={(values, { setSubmitting }) => {
            if (!isLastStep && step < validationSchemaStep.length - 1) {
              window.scrollTo({ top: 0, behavior: "smooth" })
              setSubmitting(false)
              setStep(prev => prev + 1)
              return
            }

            // just to make sure everything is fine
            fullValidationSchema.validateSync(values)

            values.supply_number = values.supply_number || ""
            values.initial_price = values.initial_price || ""
            values.minted_at = values.minted_at || ""

            createCollection(values)
              .then(() => {
                toast.success(t("collection.toastCollectionCreationSuccess"))
                navigate(
                  localizedPath({
                    defaultLang,
                    locale,
                    path: `/user/${user.publicKey}/`,
                  })
                )
              })
              .catch(err => {
                toast.error(t("collection.toastCollectionCreationFailed"))

                // these are Joi validation errors
                err?.details &&
                  Object.values(err.details).forEach(error => {
                    toast.error(error.message)
                  })

                // these are server validation errors
                err?.response?.data?.errors &&
                  Object.values(err.response.data.errors).forEach(error => {
                    toast.error(error.join("\n"))
                  })
              })
              .finally(() => {
                setSubmitting(false)
              })
          }}
        >
          {({ values, isSubmitting, setPreview, setFieldValue }) => {
            const steps = [
              <MainStep />,
              <MediasStep
                {...{ setFieldValue, values, previews, setPreviews, reader }}
              />,
              <TagsStep
                {...{ setFieldValue, tags, reader, setPreview, values }}
              />,
              <SupplyStep {...{ values }} />,
              <LinksStep {...{ values }} />,
            ]
            return (
              <Form>
                <GridContainer>
                  <div>
                    {steps[step]}
                    {step <= validationSchemaStep.length - 1 && (
                      <>
                        <p />
                        <Grid>
                          {step === 0 ? (
                            <div />
                          ) : (
                            <Button
                              disabled={isSubmitting}
                              onClick={() => {
                                window.scrollTo({ top: 0, behavior: "smooth" })
                                setStep(prev => Math.max(0, prev - 1))
                              }}
                              label={t("prev")}
                            />
                          )}

                          <Button
                            disabled={isSubmitting}
                            submit
                            data-cy="submit"
                            label={
                              isLastStep
                                ? t("collection.form.buttonCreate")
                                : t("next")
                            }
                          />
                          {isLastStep && (
                            <p>{t("collection.form.conditions")}</p>
                          )}
                        </Grid>
                      </>
                    )}
                  </div>
                  <StickyContainer>
                    <Sticky>
                      {({ style, isSticky, distanceFromTop }) => (
                        <div
                          ref={stickyRef}
                          style={{
                            ...style,
                            paddingTop: isSticky
                              ? "100px"
                              : `${
                                  distanceFromTop < 100
                                    ? 100 - distanceFromTop
                                    : 0
                                }px`,
                          }}
                        >
                          <HighlightTeaser
                            avatar={
                              <Avatar
                                size="small"
                                image={previews.icon_path}
                                alt="your own profile picture"
                              />
                            }
                            title={
                              values.name || (
                                <PlaceHolder>
                                  {placeholder.repeat(15)}
                                </PlaceHolder>
                              )
                            }
                            authors={[user.data.name]}
                            excerpt={
                              values.description || (
                                <PlaceHolder>
                                  {placeholder.repeat(45)}
                                </PlaceHolder>
                              )
                            }
                            image={previews.vertical_teaser_path}
                          />
                        </div>
                      )}
                    </Sticky>
                  </StickyContainer>
                </GridContainer>
              </Form>
            )
          }}
        </Formik>
      </Section>
    </Layout>
  )
}

const MainStep = () => {
  const { t } = useTranslation()

  return (
    <>
      <FormField title={t("collection.form.name")} name="name">
        <Field name="name" type="text" component={Input} />
      </FormField>
      <FormField
        title={t("collection.form.family")}
        help={t("collection.form.family_help")}
        name="family"
      >
        <Field name="family" type="text" component={Input} />
      </FormField>
      <FormField
        title={t("collection.form.symbol")}
        help={t("collection.form.symbol_help")}
        name="symbol"
      >
        <Field name="symbol" type="text" component={Input} />
      </FormField>
      <FormField title={t("collection.form.description")} name="description">
        <Field name="description" type="text" component={TextArea} />
      </FormField>
    </>
  )
}

const MediasStep = ({ setFieldValue, setPreviews, previews, reader }) => {
  const { t } = useTranslation()

  return (
    <>
      <FormTitle>{t("collection.form.titleImages")}</FormTitle>
      <FormHelp>{t("collection.form.helpImages")}</FormHelp>
      <FormField
        title={t("collection.form.icon_path")}
        name="icon_path"
        help={t("collection.form.icon_help")}
      >
        <FileInput
          name="icon_path"
          type="file"
          accept=".png, .jpg, .jpeg"
          onChange={e => {
            setFieldValue("icon_path", e.currentTarget.files[0])
            reader.onload = () => {
              setPreviews({
                ...previews,
                icon_path: reader.result,
              })
            }
            reader.readAsDataURL(e.currentTarget.files[0])
          }}
        />
      </FormField>
      <FormField
        title={t("collection.form.vertical_teaser_path")}
        name="vertical_teaser_path"
        help={t("collection.form.vertical_teaser_help")}
      >
        <FileInput
          name="vertical_teaser_path"
          type="file"
          accept=".png, .jpg, .jpeg"
          onChange={e => {
            setFieldValue("vertical_teaser_path", e.currentTarget.files[0])
            reader.onload = () => {
              setPreviews({
                ...previews,
                vertical_teaser_path: reader.result,
              })
            }
            reader.readAsDataURL(e.currentTarget.files[0])
          }}
        />
      </FormField>
      <FormField
        title={t("collection.form.square_teaser_path")}
        name="square_teaser_path"
        help={t("collection.form.square_teaser_help")}
      >
        <FileInput
          name="square_teaser_path"
          type="file"
          accept=".png, .jpg, .jpeg"
          onChange={e => {
            setFieldValue("square_teaser_path", e.currentTarget.files[0])
            reader.onload = () => {
              setPreviews({
                ...previews,
                square_teaser_path: reader.result,
              })
            }
            reader.readAsDataURL(e.currentTarget.files[0])
          }}
        />
      </FormField>
      <FormField
        title={t("collection.form.cover_path")}
        name="cover_path"
        help={t("collection.form.cover_help")}
      >
        <FileInput
          name="cover_path"
          type="file"
          accept=".png, .jpg, .jpeg"
          onChange={e => {
            setFieldValue("cover_path", e.currentTarget.files[0])
            reader.onload = () => {
              setPreviews({
                ...previews,
                cover_path: reader.result,
              })
            }
            reader.readAsDataURL(e.currentTarget.files[0])
          }}
        />
        <Banner size="small" image={previews.cover_path} />
      </FormField>
    </>
  )
}

const TagsStep = ({ tags }) => {
  const { t } = useTranslation()

  return (
    <>
      <FormField title={t("collection.form.tags")} name="tags">
        <Tags>
          {tags.map((item, i) => (
            <Field
              key={i}
              name="tags"
              type="checkbox"
              value={item.statamicId}
              label={item.title}
              component={Checkbox}
            />
          ))}
        </Tags>
      </FormField>
      <FormField title={t("collection.form.is_nsfw")} name="is_nsfw">
        <Field
          name="is_nsfw"
          type="checkbox"
          value="1"
          label={t("collection.form.is_nsfw_label")}
          component={Checkbox}
        />
      </FormField>
    </>
  )
}

const SupplyStep = () => {
  const { t } = useTranslation()

  return (
    <>
      <FormTitle>{t("collection.form.titleFutureCollection")}</FormTitle>
      <FormHelp>{t("collection.form.helpFutureCollection")}</FormHelp>
      <FormField
        title={t("collection.form.supply_number")}
        name="supply_number"
      >
        <Field name="supply_number" component={Input} />
      </FormField>
      <FormField
        title={t("collection.form.initial_price")}
        name="initial_price"
      >
        <Field name="initial_price" component={Input} />
      </FormField>
      <FormField title={t("collection.form.minted_at")} name="minted_at">
        <Field name="minted_at" type="date" component={Input} />
      </FormField>
    </>
  )
}

const LinksStep = () => {
  const { t } = useTranslation()

  return (
    <>
      <FormTitle>{t("collection.form.titleWebsites")}</FormTitle>
      <FormHelp>{t("collection.form.helpWebsites")}</FormHelp>
      <FormField title={t("collection.form.website")} name="website">
        <Field name="website" type="text" component={Input} />
      </FormField>
      <FormField title={t("collection.form.discord")} name="discord">
        <Field name="discord" type="text" component={Input} />
      </FormField>
      <FormField title={t("collection.form.telegram")} name="telegram">
        <Field name="telegram" type="text" component={Input} />
      </FormField>
      <FormField title={t("collection.form.instagram")} name="instagram">
        <Field name="instagram" type="text" component={Input} />
      </FormField>
      <FormField title={t("collection.form.twitter")} name="twitter">
        <Field name="twitter" type="text" component={Input} />
      </FormField>
    </>
  )
}

export default Create

export const createCollectionQuery = graphql`
  query createCollectionQuery {
    allTag {
      nodes {
        statamicId
        title
      }
    }
  }
`
